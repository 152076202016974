<span class="max-w-sm flex-1 truncate">
  {{ employee.email }}
</span>

<span class="max-w-sm flex-1 truncate">
  {{ employee.phone_nr }}
</span>

<span class="max-w-sm flex-1 truncate">
  {{ employee.mobile_nr }}
</span>
