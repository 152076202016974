import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { yearList } from '../../../../shared/date.helper';
import { AbsenteeOptionModel } from '../../../../reducers/orm/absentee-option/absentee-option.model';
import { UserNote } from '../../../../reducers/orm/employee/employee.model';
import { EmployeeService } from '../../../../reducers/orm/employee/employee.service';
import { trackByIdFn } from '../../../../shared/trackby';

@Component({
  selector: 'employee-notes',
  templateUrl: './employee-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeNotesComponent {
  @Input()
  employeeId: string;
  @Input()
  notes: UserNote[];
  @Input()
  loading = false;
  @Input()
  permissions;

  public trackById = trackByIdFn;

  constructor(private employeeService: EmployeeService) {}

  trackBy(index, note) {
    return note.id;
  }

  delete(note: UserNote) {
    this.employeeService.deleteNote(this.employeeId, note.id).subscribe();
  }
}
