import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EmployeeWithActiveContractAndDepartments } from '../../../../../reducers/orm/employee/employee.model';

@Component({
  selector: 'employee-list-details',
  templateUrl: './employee-list-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class EmployeeListDetailsComponent {
  @Input()
  public employee: EmployeeWithActiveContractAndDepartments;
}
