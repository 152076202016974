import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BadgeComponent,
  CheckboxComponent,
  DropdownModule,
  IconButtonComponent,
  IconComponent,
  InputSelectDirective,
  PaginationComponent,
  SearchInputComponent,
  SelectComponent,
} from '@sb/ui';

import { KnowledgeBaseArticleLinkModule } from '../../../pipes/knowledge-base-article-link.module';
import { SharedModule } from '../../../shared/shared.module';
import { EmployeeListActionComponent } from './cells/employee-list-action/employee-list-action.component';
import { EmployeeListCellDirective } from './cells/employee-list-cell.directive';
import { EmployeeListDetailsComponent } from './cells/employee-list-details/employee-list-details.component';
import { EmployeeListEmployeeComponent } from './cells/employee-list-employee/employee-list-employee.component';
import { EmployeeListHeaderCellDirective } from './cells/employee-list-header-cell.directive';
import { EmployeeListHeaderDetailsComponent } from './cells/employee-list-header-details/employee-list-header-details.component';
import { EmployeeListHeaderInfoComponent } from './cells/employee-list-header-info/employee-list-header-info.component';
import { EmployeeFilesComponent } from './employee-files/employee-files.component';
import { EmployeeNotesComponent } from './employee-notes/employee-notes.component';
import { EmployeePermissionsTeamsComponent } from './employee-permissions-teams/employee-permissions-teams.component';
import { EmployeeSidebarComponent } from './employee-sidebar/employee-sidebar.component';

const imports = [
  SharedModule,
  RouterModule,
  BadgeComponent,
  DropdownModule,
  IconComponent,
  IconButtonComponent,
  SearchInputComponent,
  SelectComponent,
  PaginationComponent,
  CheckboxComponent,
  InputSelectDirective,
];

const standalone = [
  EmployeeListCellDirective,
  EmployeeListHeaderCellDirective,
  EmployeeListHeaderInfoComponent,
  EmployeeListHeaderDetailsComponent,
  EmployeeListEmployeeComponent,
  EmployeeListDetailsComponent,
  EmployeeListActionComponent,
];

const declarations = [
  EmployeePermissionsTeamsComponent,
  EmployeeFilesComponent,
  EmployeeNotesComponent,
  EmployeeSidebarComponent,
];

@NgModule({
  imports: [...imports, ...standalone, KnowledgeBaseArticleLinkModule],
  declarations: declarations,
  exports: [...imports, ...standalone, ...declarations],
})
export class SharedEmployeeModule {
  public static forRoot(): ModuleWithProviders<SharedEmployeeModule> {
    return {
      ngModule: SharedEmployeeModule,
    };
  }
}
