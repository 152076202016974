import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[employee-list-cell]',
  standalone: true,
})
export class EmployeeListCellDirective {
  @HostBinding('class')
  private readonly classes = clsx('flex h-full items-center gap-1 bg-white px-1 group-hover:bg-grey-50');
}
