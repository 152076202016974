import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EmployeeModel } from '@reducers/orm/employee/employee.model';
import { DepartmentOptionsPerLocation } from '@reducers/orm/location/location.model';
import { PermissionGroupModel } from '@reducers/orm/permission-group/permission-group.model';
import { TeamModel } from '@reducers/orm/team/team.model';
import { CheckboxChangeEvent } from '@sb/ui';
import every from 'lodash-es/every';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import { Subscription } from 'rxjs';

import { trackByIdFn, trackByLocationFn } from '../../../../shared/trackby';
import { EmployeePermissionFormService } from './employee-permissions-form.service';

@Component({
  selector: 'employee-permissions-teams',
  templateUrl: './employee-permissions-teams.component.html',
  providers: [EmployeePermissionFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePermissionsTeamsComponent implements OnChanges, OnDestroy {
  public loading = true;

  @Input()
  public employee: EmployeeModel;
  @Input()
  public groups: PermissionGroupModel[];
  @Input()
  public locations: DepartmentOptionsPerLocation[];
  @Input()
  public teamsPerDepartment: { [departmentId: string]: TeamModel[] };

  @Input()
  public editOnlyTeamDepartments: string[];

  @Input()
  public form;

  public locationGroupForm: UntypedFormGroup;

  private subscription = new Subscription();

  public trackById = trackByIdFn;
  public trackByLocation = trackByLocationFn;

  public constructor(private formService: EmployeePermissionFormService) {}

  /**
   * watch for changes on UsersGroup to set The toggle status of the location UsersGroup combination
   */
  private subscribeToDepartmentForm(): void {
    this.subscription.add(
      this.form.get('Department').valueChanges.subscribe(() => {
        // select raw to also get value from disabled fields
        const departmentsFormData = this.form.get('Department').getRawValue();
        forEach(this.locations, (locationOption) => {
          const location = locationOption.location;
          //grab all departmentIds for current location
          const departmentIds = locationOption.departments.map((department) => department.id);

          //get UsersGroups status for location departments
          const departmentValues = filter(
            departmentsFormData,
            (departmentForm: any) => departmentIds.indexOf(departmentForm.id) !== -1,
          ).map((departmentForm: any) => departmentForm.UsersGroup);

          //toggle status for each group
          forEach(this.groups, (group) => {
            const allSelected = every(departmentValues, (departmentGroupStatus) => departmentGroupStatus[group.id]);

            //Update toggle status
            this.locationGroupForm.get([location.id, group.id]).setValue(allSelected);
          });
        });
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.loading && changes['employee'] && !changes['employee'].isFirstChange()) {
      this.resetForm();
    }

    if (this.loading && this.groups && this.groups.length && this.locations && this.locations.length) {
      this.initForm();
    }
  }

  private initForm(): void {
    this.locationGroupForm = this.formService.locationGroupForm(this.locations, this.groups);
    this.formService.createForm(this.form, this.locations, this.groups);

    this.subscribeToDepartmentForm();

    this.resetForm();

    this.loading = false;
  }

  private resetForm(): void {
    this.formService.setEmployeeData(this.form, this.employee, this.teamsPerDepartment);

    // departments where the user can only edit teams and not set permissions should disable permission selection
    this.editOnlyTeamDepartments?.forEach((departmentId) => {
      this.form.get(['Department', departmentId, 'UserGroups']).disable({ onlySelf: false });
      this.form.get(['Department', departmentId, 'UsersGroup']).disable({ onlySelf: false });
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.form.removeControl('Department');
  }

  public toggleGroupForLocation(locationId: string, groupId: string, event: CheckboxChangeEvent): void {
    const selected = event.checked;
    const selectedLocation = this.locations.find((location) => location.location.id === locationId);

    selectedLocation.departments.forEach((department) => {
      const control = this.form.get(['Department', department.id, 'UsersGroup', groupId]) as UntypedFormControl;
      if (control.disabled) {
        return;
      }
      control.setValue(selected, { emitEvent: false });
      control.markAsDirty();
    });

    this.form.updateValueAndValidity();
  }
}
