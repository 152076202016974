import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from '@sb/ui';

import { EmployeeWithActiveContractAndDepartments } from '../../../../../reducers/orm/employee/employee.model';
import { SBAvatarComponent } from '../../../../../shared/sb-lib/avatar/sb-avatar.component';

@Component({
  selector: 'employee-list-employee',
  templateUrl: './employee-list-employee.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, CheckboxComponent, SBAvatarComponent],
})
export class EmployeeListEmployeeComponent {
  @Input()
  public employee: EmployeeWithActiveContractAndDepartments;
  @Input()
  public selected: boolean;
  @Input()
  public showCheckbox: boolean;

  @Output()
  public toggleSelectEmployee = new EventEmitter<EmployeeWithActiveContractAndDepartments>();
}
