<div class="card">
  <div class="card-heading">
    <div class="card-heading__title">
      <span class="leading-[17px]">{{ 'Personal files' | translate }}</span>
    </div>
    <div class="card-heading__button-group heading-group-with-search">
      <sb-search-input
        class="mr-1.5"
        [formControl]="personalFilesSearchCtrl"
        [placeholder]="'Search personal file' | translate"
      ></sb-search-input>
      <button
        class="btn btn-primary flex items-center text-base"
        *ngIf="permissions.canAdd"
        [routerLink]="['', { outlets: { modal: ['employee-file', employeeId] } }]"
      >
        <icon class="si mr-[10px]" svgIcon="paperclip"></icon>
        {{ 'Add file' | translate }}
      </button>
    </div>
  </div>
  <div class="card-table">
    <content-state
      [isEmpty]="loading || files.length === 0"
      [isLoading]="loading"
      icon="paperclip"
      size="small"
      [title]="'No files found' | translate"
      [content]="'Start adding them now' | translate"
    >
      <table class="table-border">
        <thead>
          <tr>
            <th>{{ 'Title' | translate }}</th>
            <th>{{ 'Expiration date' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of paginatedPersonalFiles[personalFilesPaginationOptions.page - 1]; trackBy: trackById">
            <td>{{ file.title }}</td>
            <td>{{ file.expiration_date | date | default }}</td>

            <td class="table-border-padding__actions">
              <button
                *ngIf="file.file_name"
                (click)="download(file)"
                class="btn btn-clear btn-icon"
                aria-label="download"
                bs-tooltip
                data-title="download"
              >
                <icon svgIcon="download" class="si si-download table-border-padding__actions-icon"></icon>
              </button>
              <button
                *ngIf="permissions.canEdit"
                [routerLink]="['', { outlets: { modal: ['employee-file', employeeId, file.id] } }]"
                class="btn btn-clear btn-icon"
                aria-label="edit"
                bs-tooltip
                data-title="edit"
              >
                <icon svgIcon="edit" class="si si-download table-border-padding__actions-icon"></icon>
              </button>
              <ng-container *ngIf="permissions.canDelete">
                <action-confirm
                  class="btn btn-clear btn-icon"
                  [sbTooltip]="'Delete' | translate"
                  [sbTooltipPlacement]="'left'"
                  [attr.aria-label]="'Delete' | translate"
                  icon="delete"
                  (onConfirm)="delete(file)"
                >
                </action-confirm>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
  <div *ngIf="paginatedPersonalFiles?.length > 1" class="card-footer">
    <sb-pagination
      [totalItems]="personalFilesPaginationOptions.totalItems"
      [page]="personalFilesPaginationOptions.page"
      (pageChange)="personalPageChanged($event)"
    >
    </sb-pagination>
  </div>
</div>
