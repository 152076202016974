import { Directive, HostBinding, Input } from '@angular/core';
import { lightenHex } from '@shiftbase-com/utilities';
import { clsx } from 'clsx';

@Directive({
  selector: '[employee-list-header-cell]',
  standalone: true,
})
export class EmployeeListHeaderCellDirective {
  @Input()
  public color: string;

  @HostBinding('class')
  private get classes(): string {
    return clsx(
      'flex h-full items-center gap-1 px-1 font-semibold text-word-mark-800',
      !this.color && 'border-0 border-b border-solid border-grey-200 bg-white group-hover:bg-grey-50',
    );
  }

  @HostBinding('style.backgroundColor')
  private get backgroundColor(): string {
    if (!this.color) return null;

    return lightenHex(this.color, null, 97);
  }

  @HostBinding('style.borderBottom')
  private get borderBottom(): string {
    if (!this.color) return null;

    return `3px solid ${this.color}`;
  }
}
