import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PAGINATION_DEFAULT_ITEMS_PER_PAGE, PaginationChangeEvent } from '@sb/ui';
import chunk from 'lodash-es/chunk';
import { Subscription } from 'rxjs';

import { UserFile } from '../../../../reducers/orm/employee/employee.model';
import { EmployeeService } from '../../../../reducers/orm/employee/employee.service';
import { trackByIdFn } from '../../../../shared/trackby';
import { PaginationOptions } from '../../../+account/+integrations/shared/import/mapping/import-mapping.component';

@Component({
  selector: 'employee-files',
  templateUrl: './employee-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeFilesComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public employeeId: string;
  @Input()
  public files: UserFile[];
  @Input()
  public loading = false;
  @Input()
  public permissions;

  public personalFilesSearchCtrl = new UntypedFormControl('');

  public personalFilesPaginationOptions: PaginationOptions = {
    page: 1,
    totalItems: undefined,
  };

  public readonly itemsPerPage = PAGINATION_DEFAULT_ITEMS_PER_PAGE;

  public paginatedPersonalFiles: UserFile[][] = [];

  public trackById = trackByIdFn;

  private dataSubs = new Subscription();

  public constructor(private employeeService: EmployeeService) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.personalFilesSearchCtrl.valueChanges.subscribe((val) => {
        this.personalFilesSearch(val);
      }),
    );
  }

  private personalFilesSearch(val: string) {
    const searchValue = val.toLowerCase().trim();

    const filteredFiles = !searchValue
      ? this.files
      : this.files.filter((file: UserFile) => file.title.toLowerCase().includes(searchValue));

    this.personalFilesPaginationOptions = {
      page: 1,
      totalItems: filteredFiles.length,
    };

    this.paginatedPersonalFiles = chunk(filteredFiles, this.itemsPerPage);
  }

  public ngOnChanges() {
    this.paginatedPersonalFiles = chunk(this.files, this.itemsPerPage);
    this.personalFilesPaginationOptions.totalItems = this.files.length;
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public personalPageChanged(options: PaginationChangeEvent) {
    this.personalFilesPaginationOptions.page = options.page;
  }

  public download(file: UserFile) {
    void this.employeeService.download(file.id, file.file_name).subscribe();
  }

  public delete(file: UserFile) {
    void this.employeeService.deleteFile(this.employeeId, file.id).subscribe();
  }
}
