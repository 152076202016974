import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogConfirmHighImpactComponent } from '@app/shared/sb-lib/dialog-confirm-high-impact/dialog-confirm-high-impact.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeComponent, DropdownModule, IconButtonComponent, IconComponent } from '@sb/ui';
import { combineLatest, filter, Subscription, switchMap, take } from 'rxjs';

import { getAccountSubscription } from '../../../../../reducers/account/account.service';
import { getPermissionState, hasPermission } from '../../../../../reducers/auth/permission.helper';
import { EmployeeWithActiveContractAndDepartments } from '../../../../../reducers/orm/employee/employee.model';
import { EmployeeService } from '../../../../../reducers/orm/employee/employee.service';
import { hasReachedQuota } from '../../../../../shared/subscription-plan/subscription-quota.directive';
import { QuotaType } from '../../../../+reports/shared/subscriptions/subscription.model';
import { PermissionDirective } from '../../../../shared/permission.directive';

@Component({
  selector: 'employee-list-action',
  templateUrl: './employee-list-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    BadgeComponent,
    IconButtonComponent,
    IconComponent,
    PermissionDirective,
    DialogConfirmHighImpactComponent,
  ],
})
export class EmployeeListActionComponent implements OnDestroy, OnInit {
  @Input()
  public employee: EmployeeWithActiveContractAndDepartments;

  @ViewChild(DialogConfirmHighImpactComponent, { static: false })
  private dialogConfirmHighImpactComponent!: DialogConfirmHighImpactComponent;

  public quotaType = QuotaType;

  private dataSubs = new Subscription();

  public allowedToActivate = false;
  public allowedToAnonymize = false;
  public allowedToDeactivate = false;
  public allowedToInvite = false;

  public canActivate = false;
  public canAnonymize = false;
  public canDeactivate = false;
  public canInvite = false;
  public canResendInvite = false;

  public canViewMenu = false;

  public constructor(
    private employeeService: EmployeeService,
    private store: Store,
    private readonly destroyRef: DestroyRef,
  ) {}

  public activate(employeeId: string) {
    void this.employeeService.activate(employeeId).subscribe();
  }

  public deactivate(employeeId: string) {
    void this.employeeService.remove(employeeId).subscribe();
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public ngOnInit() {
    this.dataSubs.add(
      combineLatest([this.store.select(getPermissionState), this.store.select(getAccountSubscription)]).subscribe({
        next: ([permissionState, subscription]) => {
          this.allowedToActivate =
            hasPermission(
              { userId: this.employee.id, permissions: 'Activate users', departments: this.employee.departments },
              permissionState,
            ) && !hasReachedQuota(QuotaType.SEATS, subscription);
          this.allowedToAnonymize = hasPermission(
            { userId: this.employee.id, permissions: 'Delete users', departments: this.employee.departments },
            permissionState,
          );
          this.allowedToDeactivate = hasPermission(
            { userId: this.employee.id, permissions: 'Activate users' },
            permissionState,
          );
          this.allowedToInvite = hasPermission(
            { userId: this.employee.id, permissions: 'Edit users' },
            permissionState,
          );

          this.canActivate = this.allowedToActivate && this.employee.isActive === false;
          this.canAnonymize = this.allowedToAnonymize && !this.employee.isActive && !this.employee.anonymized;
          this.canDeactivate = this.allowedToDeactivate && this.employee.isActive;
          this.canInvite =
            this.allowedToInvite &&
            this.employee.email &&
            this.employee.email.length > 0 &&
            this.employee.invited === false;
          this.canResendInvite =
            this.allowedToInvite &&
            this.employee.email &&
            this.employee.email.length > 0 &&
            this.employee.invited === true;
          this.canViewMenu =
            this.canActivate || this.canAnonymize || this.canDeactivate || this.canInvite || this.canResendInvite;
        },
      }),
    );
  }

  public invite(employeeId: string) {
    const saveData = {
      id: employeeId,
      User: {
        id: employeeId,
        notify_employee: true,
      },
    };
    void this.employeeService.save(saveData).subscribe();
  }

  public resendEmployeeInvite(employeeId: string) {
    void this.employeeService.resendInvite({ ids: [employeeId] }).subscribe();
  }

  public anonymize() {
    if (!this.employee) {
      return;
    }

    void this.dialogConfirmHighImpactComponent
      .openDialog()
      .pipe(
        take(1),
        filter((result) => result?.confirmed),
        switchMap(() => this.employeeService.anonymize(this.employee.id)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
