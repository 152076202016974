import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { getActiveContractTypes } from '@reducers/orm/contract-type/contract-type.service';
import { canViewContracts } from '@reducers/orm/contract/contract.service';
import { getPermissionGroups } from '@reducers/orm/permission-group/permission-group.service';
import { canViewSkills, getSkillsMultiSelect } from '@reducers/orm/skill/skill.service';
import { filterPersistenceDebounceTime, PageFiltersService } from '@reducers/page-filters/page-filters.service';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';

import { lazySelect } from '../../../../shared/lazy-select.observable';
import { PlanType } from '../../../+reports/shared/subscriptions/subscription.model';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import { canViewInactiveUsers, canViewPermissions, getEmployeeSidebarFilters } from '../../employee-list.helper';

@Component({
  selector: 'employee-sidebar',
  templateUrl: './employee-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeSidebarComponent implements OnInit, OnDestroy {
  public filters;
  public canViewContracts$ = this.store.select(canViewContracts);
  public canViewPermissions$ = this.store.select(canViewPermissions);
  public canViewSkills$ = this.store.select(canViewSkills);
  public canViewInactiveUsers$ = this.store.select(canViewInactiveUsers);

  public checkboxesFormGroup = new UntypedFormGroup({});
  private checkboxesSubscription: Subscription;

  public skills = this.store.select(getSkillsMultiSelect);
  public planType = PlanType;

  public permissionGroups;
  public contractTypes;

  public multiSelectConfig = {
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    maxHeight: '100%',
  };

  private filterSubscription: Subscription;

  public constructor(
    private sidebar: SidebarComponent,
    private filterService: PageFiltersService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.filterSubscription = combineLatest([
      this.store.select(getActiveContractTypes),
      this.store.select(getPermissionGroups),
    ])
      .pipe(
        switchMap(([contractTypes, permissionGroups]) => {
          this.filters = undefined;
          this.contractTypes = contractTypes;
          this.permissionGroups = permissionGroups;

          return this.store.pipe(lazySelect(getEmployeeSidebarFilters));
        }),
        take(1),
      )
      .subscribe((filters) => {
        Object.keys(filters).forEach((groupKey) => {
          if (groupKey === 'skills') {
            const control = new UntypedFormControl(filters[groupKey]);
            this.checkboxesFormGroup.addControl(groupKey, control);
          } else {
            const groupControl = new UntypedFormGroup({});
            Object.keys(filters[groupKey]).forEach((key) => {
              const filterControl = new UntypedFormControl(filters[groupKey][key]);
              groupControl.addControl(key, filterControl);
            });

            this.checkboxesFormGroup.addControl(groupKey, groupControl);
          }
        });

        this.filters = filters;
      });

    this.checkboxesSubscription = this.checkboxesFormGroup.valueChanges
      .pipe(debounceTime(filterPersistenceDebounceTime))
      .subscribe((formValue) => {
        this.filterService.setFilters('employee', formValue);
      });
  }

  public toggle(groupKey: string) {
    const group: UntypedFormGroup = <UntypedFormGroup>this.checkboxesFormGroup.get(groupKey);
    if (!group || !group.controls) {
      return;
    }
    const controls = Object.values(group.controls);
    if (!controls || controls.length === 0) {
      return;
    }
    const currentVal = controls[0].value;
    controls.forEach((control: UntypedFormControl) => {
      control.setValue(!currentVal);
    });
  }

  public ngOnDestroy() {
    this.filterSubscription.unsubscribe();
    this.checkboxesSubscription.unsubscribe();
  }

  public close() {
    this.sidebar.close();
  }
}
