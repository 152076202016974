<ng-container *permission="['Edit users']; user: employee.id; department: employee.departments">
  <sb-badge *ngIf="employee.isActive && employee.verified && employee.invited" [color]="'blue'">
    {{ 'Active' | translate }}
  </sb-badge>
  <sb-badge *ngIf="employee.isActive && !employee.invited" [color]="'yellow'">{{ 'Not invited' | translate }}</sb-badge>
  <sb-badge *ngIf="employee.isActive && employee.invited && !employee.verified" [color]="'purple'">
    {{ 'Invited' | translate }}
  </sb-badge>
  <sb-badge *ngIf="!employee.isActive" [color]="'red'">
    {{ 'Deactivated' | translate }}
  </sb-badge>
</ng-container>

<ng-container *ngIf="canViewMenu">
  <button [sbDropdownToggle]="menu" sb-icon-button [color]="'transparent'">
    <sb-icon [name]="'ellipsis-v'"></sb-icon>
  </button>
  <ng-template #menu>
    <div sbDropdownMenu>
      @if (canInvite) {
        <button sb-button sbDropdownItemIcon [icon]="'paper-plane'" (click)="invite(employee.id)">
          {{ 'Invite' | translate }}
        </button>
      }
      @if (canResendInvite) {
        <button sb-button sbDropdownItemIcon [icon]="'paper-plane'" (click)="resendEmployeeInvite(employee.id)">
          {{ 'Reinvite' | translate }}
        </button>
      }
      @if (canDeactivate) {
        <button sb-button sbDropdownItemIcon [icon]="'trash'" (click)="deactivate(employee.id)">
          {{ 'Deactivate' | translate }}
        </button>
      }
      @if (canActivate) {
        <button sb-button sbDropdownItemIcon [icon]="'check'" (click)="activate(employee.id)">
          {{ 'Activate' | translate }}
        </button>
      }
      @if (canAnonymize) {
        <button sb-button sbDropdownItemIcon [icon]="'times'" (click)="anonymize()">
          {{ 'Anonymize' | translate }}
        </button>
      }
    </div>
  </ng-template>

  <app-dialog-confirm-high-impact
    [title]="'Anonymize employee?' | translate"
    [confirmButtonLabel]="'Anonymize employee' | translate"
    [matchLabel]="'Type employee’s name below' | translate"
    [matchValue]="employee.name"
    [matchError]="'Username is incorrect.' | translate"
  >
    <p>
      {{
        'Deleting an employee with all associated data cannot be undone. Timesheets will be retained, but will no longer be traceable to the employee in question.You can confirm this action by entering the employee’s name below:'
          | translate
      }}
    </p>
  </app-dialog-confirm-high-impact>
</ng-container>
