import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxComponent } from '@sb/ui';

@Component({
  selector: 'employee-list-header-info',
  templateUrl: './employee-list-header-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CheckboxComponent],
})
export class EmployeeListHeaderInfoComponent {
  @Input()
  public title: string;
  @Input()
  public showCheckbox: boolean;
  @Input()
  public checked: boolean;

  @Output()
  public toggleSelectAllEmployees = new EventEmitter<null>();
}
