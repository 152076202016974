<!-- TODO the checkbox component should define an appropriate background -->
<sb-checkbox
  *ngIf="showCheckbox"
  [checked]="checked"
  class="bg-white"
  (change)="toggleSelectAllEmployees.emit()"
></sb-checkbox>

<span class="truncate px-5">
  {{ title }}
</span>
