<div class="card card-default">
  <div class="card-heading">
    <div class="card-heading__title">
      {{ 'Teams and permissions' | translate }}
      <knowledge-base-article [article]="'user-permissions'"></knowledge-base-article>
    </div>
  </div>
  <div class="card-table">
    <ng-container *ngIf="!loading">
      <div ScrollWindow>
        <div
          [ngStyle]="{
            'min-width': groups.length * 100 + 400 + 12 + 'px'
          }"
        >
          <div
            FixedHeader
            [ngStyle]="{
              'min-width': groups.length * 100 + 400 + 12 + 'px'
            }"
            class="team-permissions-layout__header hours-overview"
          >
            <div class="team-permissions-layout__header--location-entry"></div>
            <div class="team-permissions-layout__header--team-entry">
              {{ 'Team' | translate }}
              <span
                *ngIf="
                  form.get('Department').invalid &&
                  form.get('Department').errors &&
                  form.get('Department').errors['missingTeam']
                "
                [ngClass]="{
                  'team-permissions-layout__has-danger':
                    form.get('Department').invalid &&
                    form.get('Department').errors &&
                    form.get('Department').errors['missingTeam']
                }"
                class="form-control-feedback team-permissions-layout__correction"
              >
                <icon
                  class="si"
                  svgIcon="exclamation-triangle"
                  [sbTooltipPlacement]="'top'"
                  [sbTooltip]="'Select at least one team' | translate"
                >
                </icon>
              </span>
            </div>

            <div class="team-permissions-layout__header--groups">
              @for (group of groups; track group.id) {
                <div class="team-permissions-layout__header--group">
                  {{ group.name }}
                </div>
              }
            </div>
          </div>

          <div
            *ngFor="let location of locations; trackBy: trackByLocation"
            [formGroup]="locationGroupForm.get(location.location.id)"
            class="team-permissions-layout__location-wrapper"
          >
            <div class="team-permissions-layout__location-row">
              <div class="team-permissions-layout__location-row--title">
                {{ location.location?.name }}
              </div>

              <div class="team-permissions-layout__location-row--open-entry"></div>

              <div class="team-permissions-layout__location-row--groups">
                @for (group of groups; track group.id) {
                  <div class="team-permissions-layout__location-row--group">
                    <sb-checkbox
                      [formControlName]="group.id"
                      (change)="toggleGroupForLocation(location.location.id, group.id, $event)"
                    ></sb-checkbox>
                  </div>
                }
              </div>
            </div>

            <div
              *ngFor="let department of location.departments; trackBy: trackById"
              class="team-permissions-layout__location-row team-permissions-layout__department-row"
            >
              <div class="team-permissions-layout__location-row--title">
                {{ department.name }}
                &nbsp;

                <span
                  *ngIf="
                    form.get(['Department', department.id]).invalid &&
                    form.get(['Department', department.id]).errors['missingPermission'] &&
                    form.get(['Department', department.id]).dirty
                  "
                  [ngClass]="{
                    'team-permissions-layout__has-danger':
                      form.get(['Department', department.id]).invalid &&
                      form.get(['Department', department.id]).errors['missingPermission'] &&
                      form.get(['Department', department.id]).dirty
                  }"
                  class="form-control-feedback"
                >
                  <icon
                    class="si"
                    svgIcon="exclamation-triangle"
                    [sbTooltipPlacement]="'top'"
                    [sbTooltip]="'The employee needs permissions for each team he or she is added to' | translate"
                  >
                  </icon>
                </span>
              </div>

              <div class="team-permissions-layout__location-row--open-entry">
                <select
                  sbInput
                  [formControl]="form.get(['Department', department.id]).get('team_id')"
                  [name]="department.id + '|team_id'"
                >
                  <option [value]=""></option>
                  @for (team of teamsPerDepartment[department.id]; track team.id) {
                    <option [value]="team.id">
                      {{ team.name }}
                    </option>
                  }
                </select>
              </div>

              <div class="team-permissions-layout__location-row--groups">
                @for (group of groups; track group.id) {
                  <div class="team-permissions-layout__location-row--group">
                    <sb-checkbox
                      [name]="department.id + '|group|' + group.id"
                      [formControl]="form.get(['Department', department.id]).get(['UsersGroup', group.id])"
                    >
                    </sb-checkbox>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <content-state *ngIf="loading" [isLoading]="loading" icon="newdocument" size="small"> </content-state>
  </div>
</div>
