<form>
  <div class="flex h-14 items-center justify-between bg-primary-500 pl-3 font-semibold text-white">
    <div class="sidebar__nav-header--title pr-2.5">{{ 'Employee filters' | translate }}</div>
    <div class="sidebar__nav-header--close">
      <button sb-button [color]="'primary'" (click)="close()" class="sb-button-responsive-label">
        {{ 'Close' | translate }}
        <sb-icon iconPositionEnd [name]="'chevron-right'"></sb-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="filters">
    <div
      class="flex h-10 cursor-pointer items-center bg-grey-100 pl-3 font-medium text-word-mark-800"
      (click)="toggle('contract')"
    >
      {{ 'Status' | translate }}
    </div>
    <div class="sidebar__content">
      <div class="sidebar__element-container">
        <label class="checkbox">
          <input type="checkbox" class="checkbox-input" [formControl]="checkboxesFormGroup.get(['status', 'active'])" />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ 'Active' | translate }}</span>
        </label>
      </div>
      <div class="sidebar__element-container" *ngIf="canViewInactiveUsers$ | async">
        <label class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [formControl]="checkboxesFormGroup.get(['status', 'inactive'])"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ 'Deactivated' | translate }}</span>
        </label>
      </div>
      <div class="sidebar__element-container">
        <label class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [formControl]="checkboxesFormGroup.get(['status', 'invited'])"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ 'Invited' | translate }}</span>
        </label>
      </div>
      <div class="sidebar__element-container">
        <label class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [formControl]="checkboxesFormGroup.get(['status', 'notInvited'])"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ 'Not invited' | translate }}</span>
        </label>
      </div>
    </div>

    <ng-container *subscriptionPlan="planType.BASIC">
      <ng-container *ngIf="canViewContracts$ | async">
        <div
          class="flex h-10 cursor-pointer items-center bg-grey-100 pl-3 font-medium text-word-mark-800"
          (click)="toggle('contract')"
        >
          {{ 'Contract types' | translate }}
        </div>
        <div class="sidebar__content">
          <div class="sidebar__element-container">
            <label class="checkbox">
              <input
                type="checkbox"
                class="checkbox-input"
                [formControl]="checkboxesFormGroup.get(['contract', 'noContract'])"
              />
              <span class="checkbox-indicator"></span>
              <span class="checkbox-description text-word-mark-800">{{ 'No active contract' | translate }}</span>
            </label>
          </div>
          <div *ngFor="let contractType of contractTypes" class="sidebar__element-container">
            <label class="checkbox">
              <input
                type="checkbox"
                class="checkbox-input"
                [formControl]="checkboxesFormGroup.get(['contract', contractType.id])"
              />
              <span class="checkbox-indicator"></span>
              <span class="checkbox-description text-word-mark-800">{{ contractType.name }}</span>
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="canViewPermissions$ | async">
      <div
        class="flex h-10 cursor-pointer items-center bg-grey-100 pl-3 font-medium text-word-mark-800"
        (click)="toggle('group')"
      >
        {{ 'Permission groups' | translate }}
      </div>
      <div class="sidebar__content">
        <div *ngFor="let permissionGroup of permissionGroups" class="sidebar__element-container">
          <label class="checkbox">
            <input
              type="checkbox"
              class="checkbox-input"
              [formControl]="checkboxesFormGroup.get(['group', permissionGroup.id])"
            />
            <span class="checkbox-indicator"></span>
            <span class="checkbox-description text-word-mark-800">{{ permissionGroup.name }}</span>
          </label>
        </div>
      </div>
    </ng-container>
    <ng-container *subscriptionPlan="planType.EARLY_ADOPTER">
      <ng-container *ngIf="(canViewSkills$ | async) && (skills | async).length > 0">
        <dl-multiselect
          inlineTextClasses="font-normal text-word-mark-800"
          [formControl]="checkboxesFormGroup.get(['skills'])"
          [config]="multiSelectConfig"
          [items]="skills | async"
          [dark]="true"
          [header]="'Skills' | translate"
        >
        </dl-multiselect>
      </ng-container>
    </ng-container>
  </ng-container>
</form>
