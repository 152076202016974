<!-- TODO the checkbox component should define an appropriate background -->
<sb-checkbox
  *ngIf="showCheckbox"
  [checked]="selected"
  class="bg-white"
  (change)="toggleSelectEmployee.emit(employee)"
></sb-checkbox>

<sb-avatar class="ml-5" [employee]="employee" [size]="30"></sb-avatar>

<a class="truncate font-semibold" [routerLink]="['/employees', employee.id, 'overview']"> {{ employee.name }} </a>
