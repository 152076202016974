<div class="card">
  <div class="card-heading">
    <div class="card-heading__title" translate>Personal notes</div>
    <div
      class="btn btn-primary flex items-center text-base"
      *ngIf="permissions.canEdit"
      [routerLink]="['', { outlets: { modal: ['employee-note', employeeId] } }]"
    >
      <icon class="si mr-[10px]" svgIcon="newdocument"></icon>
      {{ 'Add note' | translate }}
    </div>
  </div>
  <div class="card-table">
    <content-state
      [isEmpty]="!notes.length"
      [isLoading]="loading"
      icon="newdocument"
      size="small"
      [title]="'No notes found' | translate"
      [content]="'Start adding them now' | translate"
    >
      <table class="table-border">
        <thead>
          <tr>
            <th translate>Date</th>
            <th translate>Note</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let note of notes; trackBy: trackById">
              <td>{{ note.date | date }}</td>
              <td>{{ note.note }}</td>
              <td class="table-border-padding__actions">
                <button
                  *ngIf="permissions.canEdit"
                  [routerLink]="['', { outlets: { modal: ['employee-note', employeeId, note.id] } }]"
                  class="btn btn-clear btn-icon"
                  aria-label="edit"
                  bs-tooltip
                  data-title="edit"
                >
                  <icon svgIcon="edit" class="si si-download table-border-padding__actions-icon"></icon>
                </button>
                <ng-container *ngIf="permissions.canDelete">
                  <action-confirm
                    class="btn btn-clear btn-icon"
                    [sbTooltip]="'Delete' | translate"
                    [sbTooltipPlacement]="'left'"
                    [attr.aria-label]="'Delete' | translate"
                    icon="delete"
                    (onConfirm)="delete(note)"
                  >
                  </action-confirm>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
